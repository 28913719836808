import { Component, OnInit } from '@angular/core';
import {AdminService} from '../admin.service';
import {Order, PopupResult, FoodSubItem,CuttingSummaryItem, FoodSubItemUpdateRequest} from '../order';
import {MatTableDataSource} from '@angular/material/table';
import { GroceryOrderPackerSelectionComponent } from '../grocery-order-packer-selection/grocery-order-packer-selection.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { OrderService } from '../order.service';
import { GenerateDeliveryRoute } from '../super-admin/DeliveryRoute';
import { HttpClient, HttpHeaders, HttpErrorResponse,} from '@angular/common/http';
import {SelectionModel} from '@angular/cdk/collections';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {CONSTANTS} from '../constants';
import {SnackbarComponent} from '../snackbar/snackbar.component';
import { throws } from 'assert';
import { MenuItem } from '../_models/menu-changes';
import { each } from 'jquery';
import { RefundComponentComponent } from '../refund-component/refund-component.component';
import { Validators } from '@angular/forms';
import { FormGroup, FormBuilder } from  '@angular/forms';

//import {MatTimepickerModule} from '@angular/material/timepicker';

@Component({
  selector: 'app-todays-orders',
  templateUrl: './todays-orders.component.html',
  styleUrls: ['./todays-orders.component.css']
})
export class TodaysOrdersComponent implements OnInit {
  result: PopupResult;
  isLoading:boolean = false;
  orders : Order[];
  foodSubItems : FoodSubItem[];
  juiceSubItems : FoodSubItem[];
  foodSubItemsTemp: Array<FoodSubItem> = new Array();
  juiceSubItemsTemp: Array<FoodSubItem> = new Array();
  filteredFoodSubItems : FoodSubItem[];
  cuttingSummary: Array<CuttingSummaryItem> = new Array();
  juiceSummary: Array<CuttingSummaryItem> = new Array();
  completedOrders : Array<Order> = new Array();

  reportUrl:string;
  allInvoicesURL:string;
  packingReport: string;
  cuttingReport: string;
  dataSource:any;
  displayedColumns: string[] =[ 'select' , 'Track', 'Order ID','Started', 'Customer Name', 
    'Address', 'PickedUp','exp delievery'];
  cuttingDisplayedColumns: string[] =[ 'select', 'Name','Type','Quantity','OrderId','driver',  'Amt'];

  cuttingSummaryHeaders: string[] =['Name', 'summary-cuttype','summary-total', 'summary-completed' ];

  selection = new SelectionModel<Order>(true, []);

  cuttingSelection = new SelectionModel<FoodSubItem>(true, []);

  disableReportsButton:boolean = true;
  disableEmailButton:boolean = true;
  cuttindData:any;
  juiceData:any;

  cuttingSummaryForDisplay: any;
  juiceSummaryForDisplay: any;
  seqList: Array<number> = new Array();

  expecteDeliveryTimeForm: FormGroup;

  deliveryAssociatesArr: Array<string> = new Array();
  checkMark:string = "../../assets/tick.png";
  messageMark:string = "../../assets/message-icon-png-13.png";
  constructor(private adminService:AdminService, private formBuilder:FormBuilder,
    private orderService:OrderService, private http: HttpClient,
    public dialog: MatDialog, private _snackBar: MatSnackBar) { 
      this.pouplateSeqList();
    }

  ngOnInit(): void {
    this.onLoad()  
  }
  

 
  onLoad() {
    const today = new Date();
      this.isLoading = true;
      this.adminService.getTodaysOrders()
      
        .subscribe(result => {
          this.isLoading = false;
            this.orders = result.items as Order[];
           
            //this.dataSource = new MatTableDataSource(this.orders);
              this.orders.forEach(item => {
                console.log(" --> " + item.orderProcessStartTime);
                item.editScreen = false;
                //item.expectedDeliveryTime = "";
                if ( item.orderProcessStartTime?.toString().length > 0 ) {
                  this.completedOrders.push(item);
                  console.log("MATCHES");
                } else {
                 console.log("DO NOT MATCH")
                
                // this.completedOrders.push(item);
               }
             });
             this.dataSource = new MatTableDataSource(this.orders);
           }
          );
    }

    setStartedStatus(orderId:number){
      this.showAssociateSelectionWindow(orderId);
      /*
      this.isLoading = true;
      this.adminService.setOrderStarted(orderId)
      
        .subscribe(result => {
          this.isLoading = false;
               this.onLoad();        
            }
          );
          */
    }
    setPickupStatus(orderId:number){
      this.isLoading = true;
      this.adminService.setOrderPickedUp(orderId)
      
        .subscribe(result => {
          this.isLoading = false;
               this.onLoad();        
            }
          );
    }

    showAssociateSelectionWindow(orderId:number)  {
      //alert("test");
     // this.openSnackBar('Start Date should not be greater than End Date', CONSTANTS.FAILURE_MESSG_STYLE);
     var  bodyMessage:Array<string> = new Array();
     bodyMessage.push("ssilly");
     
     const dialogRef = this.dialog.open(GroceryOrderPackerSelectionComponent, {
      width: '350px',
      data: {
        titleIconText:"fastfood",
        title: "New Order Request", message: bodyMessage, 
        yesButtonMessage:"Accept",
        noButtonMessage:"Decline", 
        result:this.result
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed : ' + result.userMessage);
      this.setPackingAssociate(result.userMessage, orderId)
    });
    }
  
    addToPrintQueue(orderId:number) {
      this.isLoading = true;
      this.adminService.addToPrintQueue(orderId) .subscribe(result => {
        this.isLoading = false;
            // this.URLtoShare = "https://portal.iperkz.com/#/" + result.message
            this.onLoad();
            this.isLoading = false;
          }
        );
    }

    setPackingAssociate(associate:string, orderId:number) {
      var postData: GenerateDeliveryRoute = new GenerateDeliveryRoute();
      postData.associate = associate;
      postData.orders = this.orders.filter(val=>val.customerOrderId == orderId);
      this.isLoading = true;
      this.adminService.setOrderStarted(postData)
        
          .subscribe(result => {
            this.isLoading = false;
                // this.URLtoShare = "https://portal.iperkz.com/#/" + result.message
                this.onLoad();
                 
              }
            );
    }

    printConsolidated() {
      var list:number[] = this.getOrdersList();
      
    
      this.adminService.getConsolidatedReport(list).subscribe(result => {
        this.isLoading = false;
            // this.URLtoShare = "https://portal.iperkz.com/#/" + result.message
            this.reportUrl = result.reportUrl;
            //this.test(this.reportUrl);
          }
        );
    }

    printAllInvoices() {

      this.isLoading = true;
      var list:number[] = this.getOrdersList();
      
      this.adminService.getAllTodaysInvoices(list).subscribe(result => {
        this.isLoading = false;
            // this.URLtoShare = "https://portal.iperkz.com/#/" + result.message
            this.allInvoicesURL = result.reportUrl;
            //this.test(this.reportUrl);
          }
        );
    }

    getCuttingReport() {
      var list:number[] = this.getOrdersList();
      //alert(list);
      
      this.isLoading = true;
      //Nathan
      this.adminService.getCuttingReport(list).subscribe(result => {
        this.isLoading = false;
            this.cuttingReport = result.reportUrl;
          }
        );
        this.adminService.getCuttingData(list).subscribe(result => {
          this.isLoading = false;
             // alert(result.items.length);
              this.foodSubItems = result.items as FoodSubItem[];
              this.juiceSubItemsTemp = new Array();
              var index:number =0;
              this.foodSubItemsTemp.length = 0;

              this.foodSubItems.forEach (foodItem=>{
                console.log("---> " + foodItem.menuCode)
                if (foodItem.menuCode === 'Fresh Juice') {
                    this.juiceSubItemsTemp.push(foodItem);
                } else {
                  this.foodSubItemsTemp.push(foodItem);
                }
                index++;
              })
              this.foodSubItems = this.foodSubItemsTemp;
              this.juiceSubItems = this.juiceSubItemsTemp;

              this.cuttindData = new MatTableDataSource(this.foodSubItems);
              this.juiceData = new MatTableDataSource(this.juiceSubItems);
              this.getCuttingSummary(this.foodSubItems);
              this.getDeliveryAssociates(this.foodSubItems);
              this.cuttingSummaryForDisplay = new MatTableDataSource(this.cuttingSummary);
        
              this.getJuiceSummary(this.juiceSubItems);
              this.juiceSummaryForDisplay = new MatTableDataSource(this.juiceSummary);
              console.log (" @@@@@@ "  + this.cuttingSummary.length);
            }
          );
        
    }

    getJuicesCount() {
      var count:number = 0;
      this.juiceSubItems?.forEach (row =>{
        count = count + row.count;
       })
       return count;
    }

    getCuttingCount() {
     return this.foodSubItems?.length
    }

    getDeliveryAssociates(arr:FoodSubItem[]) {
      var itemName:string = "All";
     arr.forEach (row =>{
      if (itemName != row.deliveryAssociate) {
        if (!this.deliveryAssociatesArr.includes(itemName)) {
         this.deliveryAssociatesArr.push(itemName);
        }
        
        itemName = row.deliveryAssociate;
      }
     })
     if (!this.deliveryAssociatesArr.includes(itemName)) {
     this.deliveryAssociatesArr.push(itemName);
     }
     
      
    }

    getCuttingSummary(arr:FoodSubItem[]) {
      this.cuttingSummary = new Array();
      var itemCutType:string = 'n/a';
      var itemName:string = "";
      var name:string = "";
      var ct:number = 0;
      var completedCt:number = 0;

     arr.forEach(row => {
      console.log( row.cutName + ' -AA-' + row.cutVisibleText + ' , '+ row.cutStatus);
        
      if (name != row.cutVisibleText + ' - ' +row.cutName  ) {
         
        if (ct != 0) {
         // console.log( itemCutType + ' VV-' + itemName + ' -- ' + ct  + ' , '+ row.status);
          var summaryItem: CuttingSummaryItem = new CuttingSummaryItem();
          summaryItem.cutType = itemCutType;
          summaryItem.name = itemName;
          summaryItem.count = ct;
          summaryItem.completedCount = completedCt;
          ct = 0;
          completedCt = 0;
          this.cuttingSummary.push (summaryItem);
        }
        name = row.cutVisibleText + ' - ' +row.cutName ;
        ct = row.count
        if (row.cutStatus == 1) {
          completedCt = row.count
        }
        itemName = row.cutName;
        itemCutType = row.cutVisibleText;
      } else {
        ct = ct + row.count;
        if (row.cutStatus == 1) {
          completedCt = completedCt + row.count
        }
      }
     })
     var summaryItem: CuttingSummaryItem = new CuttingSummaryItem();
     summaryItem.cutType = itemCutType;
     summaryItem.name = itemName;
     summaryItem.count = ct;
     summaryItem.completedCount = completedCt;
     this.cuttingSummary.push (summaryItem);
     console.log( itemCutType + '-' + itemName + ' -- ' + ct)
    }

    getJuiceSummary(arr:FoodSubItem[]) {
      this.juiceSummary = new Array();
      var itemCutType:string = 'n/a';
      var itemName:string = "";
      var name:string = "";
      var ct:number = 0;
      var completedCt:number = 0;

     arr.forEach(row => {
      console.log( row.cutName + ' -AA-' + row.cutVisibleText + ' , '+ row.cutStatus);
        
      if (name != row.cutVisibleText + ' - ' +row.cutName  ) {
         
        if (ct != 0) {
         // console.log( itemCutType + ' VV-' + itemName + ' -- ' + ct  + ' , '+ row.status);
          var summaryItem: CuttingSummaryItem = new CuttingSummaryItem();
          summaryItem.cutType = itemCutType;
          summaryItem.name = itemName;
          summaryItem.count = ct;
          summaryItem.completedCount = completedCt;
          ct = 0;
          completedCt = 0;
          this.juiceSummary.push (summaryItem);
        }
        name = row.cutVisibleText + ' - ' +row.cutName ;
        ct = row.count
        if (row.cutStatus == 1) {
          completedCt = row.count
        }
        itemName = row.cutName;
        itemCutType = row.cutVisibleText;
      } else {
        ct = ct + row.count;
        if (row.cutStatus == 1) {
          completedCt = completedCt + row.count
        }
      }
     })
     var summaryItem: CuttingSummaryItem = new CuttingSummaryItem();
     summaryItem.cutType = itemCutType;
     summaryItem.name = itemName;
     summaryItem.count = ct;
     summaryItem.completedCount = completedCt;
     this.juiceSummary.push (summaryItem);
     console.log( itemCutType + '-' + itemName + ' -- ' + ct)
    }


    getPackingReport() {
      var list:number[] = this.getOrdersList();
      //alert(list);
      
      this.isLoading = true;
      
      this.adminService.getPackingReport(list).subscribe(result => {
        this.isLoading = false;
            // this.URLtoShare = "https://portal.iperkz.com/#/" + result.message
           
            this.packingReport = result.reportUrl;
            //this.test(this.reportUrl);
          }
        );
        
    }

    test(urlParam:string) {
      const url = urlParam;
      this.http.get(url, {
        responseType: 'blob'
      }).subscribe(
        (response) => { // download file
          const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const blobUrl = URL.createObjectURL(blob);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = blobUrl;
          document.body.appendChild(iframe);
          iframe?.contentWindow.print();
          /*
          var blob = new Blob([response.blob()], {type: 'application/pdf'});
          const blobUrl = URL.createObjectURL(blob);
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = blobUrl;
            document.body.appendChild(iframe);
            iframe.contentWindow.print();
            */
      });
    }

    setSendButtonStatusMasterCheckbox(){
     
      if(this.selection.hasValue() ) {
        this.disableReportsButton  = false;
      } else {
        this.disableReportsButton  = true;
      }
      
      return this.selection.hasValue() && this.isAllSelected();
    }

    setCuttingMasterCheckBox(){
     //do nothing
    }

    isAllSelected() {

   
      if (this.dataSource == null) {
        return;
      }
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }

    isAllCuttingSelected() {

   
      if (this.cuttindData == null) {
        return;
      }
      const numSelected = this.cuttingSelection.selected.length;
      const numRows = this.cuttindData.data.length;
      return numSelected === numRows;
    }

    /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Order): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.customerOrderId}`;
  }

  cuttingLabelCheckbox(row?: FoodSubItem): string {
    if (!row) {
      return `${this.isAllCuttingSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.cuttingSelection.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`;
  }

   /** Selects all rows if they are not all selected; otherwise clear selection. */
   masterToggle() {
    if (this.dataSource == null) {
      return;
    }
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  masterCuttingToggle() {
    
  }

  onCuttingCheckboxChanged(ev:Event, row:any){
    //nathanx
    this.cuttingSelection.toggle(row) 
    //updateCuttingRecord
    if ( row.cutStatus == 1)
    {
      row.cutStatus = 0
    }  else {
      row.cutStatus = 1
    }

    this.isLoading = true;
    var record = new FoodSubItemUpdateRequest ();
    record.status = row.cutStatus;
    record.source = row.source;
    record.id = row.id;

      this.adminService.updateCuttingRecord(record)
      
        .subscribe(result => {
            //this.isLoading = false;
               //this.onLoad(); 
               this.getCuttingReport();    
               this.isLoading = false;   
            }
          );
    
   
  }

  setSendButtonStatus(ev:Event, row:any){
    var list:number[] = new Array();
   
    this.dataSource.data.forEach(row => {
      if (this.selection.isSelected(row) ) {
        list.push(row.customerOrderId )
      }
    });
    var selCt:number = 0;
    if ( this.selection.isSelected(row)) {
      selCt = list.length-1;
    } else {
      selCt = list.length+1;
    }
    if (selCt == 0)  { 
         this.disableReportsButton  = true;
         this.disableEmailButton = true;
    } else  if ( selCt == 1) {
      this.disableEmailButton = false;
    } else {
      this.disableReportsButton = false;
      this.disableEmailButton = true;
    } 
   
    if (ev) {
      this.selection.toggle(row) 
    }else {
      return null;
    } 
  
  }

  getSelectedOrderObjects():Array<Order> {
    //this.openSnackBar('Please select one ore more Daily Specials to send Notifications',CONSTANTS.SUCCESS_MESSG_STYLE);
    // return;
    var list:Array<Order> = new Array();
    this.dataSource.data.forEach(row => {
      if (this.selection.isSelected(row) ) {
        list.push(row )
      }
    });
    if (list.length == 0) {
     
      this.openSnackBar('Please select one ore more Daily Specials to send Notifications',CONSTANTS.FAILURE_MESSG_STYLE);
    }
   return list;
  }

  getOrdersList():number[] {
    //this.openSnackBar('Please select one ore more Daily Specials to send Notifications',CONSTANTS.SUCCESS_MESSG_STYLE);
    // return;
    var list:number[] = new Array();
    this.dataSource.data.forEach(row => {
      if (this.selection.isSelected(row) ) {
        list.push(row.customerOrderId )
      }
    });
    if (list.length == 0) {
     
      this.openSnackBar('Please select one ore more Daily Specials to send Notifications',CONSTANTS.FAILURE_MESSG_STYLE);
    }
   return list;
  }
    
  openSnackBar(message: string, panelClass: string) {
    
    var matConfig:MatSnackBarConfig = new MatSnackBarConfig();
    matConfig.verticalPosition = "top";
    matConfig.horizontalPosition ="center";
    matConfig.duration = 4000;
    matConfig.panelClass  = [panelClass];
    matConfig.data =message;
    this._snackBar.openFromComponent(SnackbarComponent, matConfig);
  }

  refreshCuttingInfo() {
    this.cuttindData = new MatTableDataSource(null);
    this.cuttindData = new MatTableDataSource(this.foodSubItems);
   
  }
  getAllTodaysCuttings() {
    var list:number[] = new Array();
    this.orders.forEach(element => {
     list.push(element.customerOrderId);
  
     });

     this.adminService.getTodaysOrders()
      
     .subscribe(result => {
       this.isLoading = false;
         this.orders = result.items as Order[];
         this.dataSource = new MatTableDataSource(this.orders);
     }
     );
     
     this.adminService.getCuttingData(list).subscribe(result => {
      this.isLoading = false;
         // alert(result.items.length);
          this.foodSubItems = result.items as FoodSubItem[];
          this.cuttindData = new MatTableDataSource(this.foodSubItems);
        }
      );
    /*
    this.addressList.forEach(element => {
        if (element.id == this.addressId) {
          this.address = element;
        }
      });
    */
  }

  associateChange(event) {
   // this.isLoading = true;
   // alert(event.value);
    //filteredFoodSubItems
    this.filteredFoodSubItems = new Array();
    if (event.value != 'All') {
        this.foodSubItems.forEach(row => {
        console.log (row.deliveryAssociate + " == " + event.value);
          if (row.deliveryAssociate == event.value) {
            this.filteredFoodSubItems.push (row)
          }
        }) ;
    } else {
     this.filteredFoodSubItems = this.foodSubItems
   }
    this.cuttindData = new MatTableDataSource(this.filteredFoodSubItems);
    this.getCuttingSummary(this.filteredFoodSubItems);
    this.cuttingSummaryForDisplay = new MatTableDataSource(this.cuttingSummary);
  }

  onEmail() {
    
    var  bodyMessage:Array<string> = new Array();
    
   bodyMessage.push("test");
   
    const dialogRef = this.dialog.open( RefundComponentComponent, {
      width: '90%', height:'80%',
      disableClose: true,
      data: {
        order: this.getSelectedOrderObjects()[0],
      }
      
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        //alert ("refresh me");
        //this.getMenuInformation();
        this.onLoad();
      } else {
        //alert ("Dont reresh me");
      }
    });
    
  }

  onMouseEnter(editItem:Order){
    console.log(" Yahooo enter" );
    editItem.expectedDeliveryTimeEditable = true; 
  }

  onMouseLeave(editItem:Order){
    console.log(" Yahooo exit :"  + editItem.editScreen );
    editItem.expectedDeliveryTimeEditable = false; 
  }

  showEditItems(editItem:Order) {
    //alert("Show Edit screen");
    this.createForm(editItem);
  }

  createForm(editItem:Order){
    
    this.expecteDeliveryTimeForm = this.formBuilder.group({
        deliveryTime: [editItem.expectedDeliveryTime,  Validators.required]
    });
    editItem.editScreen = true;
    //this.expecteDeliveryTimeForm?.setValue()
  }

  onExpDeliverySave(editItem:Order) {
    //editItem.editScreen = false;
    editItem.expectedDeliveryTime = this.expecteDeliveryTimeForm?.get("deliveryTime")?.value;
   var date:Date = this.expecteDeliveryTimeForm?.get("deliveryTime")?.value;
   //alert(date);
    var dateStr = "";
    dateStr = dateStr + date.getFullYear();
   // alert(dateStr);
    dateStr = dateStr + "-";
    dateStr = dateStr + date.getMonth()+1;
    dateStr = dateStr + "-";
    dateStr = dateStr = dateStr + date.getDate();
    dateStr = dateStr + " "
    dateStr = dateStr + date.getHours();
    dateStr = dateStr + ":";
    dateStr = dateStr + date.getMinutes();
    dateStr = dateStr + ":"
    dateStr = dateStr + date.getSeconds();
   
   this.adminService.saveExpectedDeliveryDetails(
      editItem.deliverySeq, dateStr,
       editItem.customerOrderId)
      
        .subscribe(result => {
            if (result.responseCode != - 1) {
              //alert ("update success");
              editItem.editScreen = false;
            //  this.onLoad();
            //  this.dataSource = new MatTableDataSource(this.orders);
             // editItem.expectedDeliveryTime = dateStr;
            } else {
              alert ("update failed");
              this.onLoad();
            }
          }
          
          );
    //alert (editItem.expectedDeliveryTime + " -- " + editItem.sequenceNumber);
  }

  pouplateSeqList() {
    for (let index =0; index<31; index++) {
      this.seqList.push(index);
    }
  }

  onSeqChange(val:any, editItem:Order): void {
    editItem.deliverySeq = Number(val);
    /*
    this.currentCategoryID = Number(val);
    let category = this.categoryList.find(cat => cat.categoryId === Number(val));
    this.currentCategory = category?.categoryName || '';
    console.log(this.currentCategory, this.currentCategoryID);
    */
}
//disableEmailButton
}
