
<div class="sticky-top" >
  <mat-toolbar   style="height:90px"   class="fadeshow1" >
    <mat-toolbar-row style="height: 200px;" >
      <h1 style="text-align: center;">  No. of Orders : {{orders?.length}}&nbsp;&nbsp; Completed : {{completedOrders?.length}}
        <button mat-raised-button color="primary" style="height:30px;vertical-align: top;"
        type="submit" [disabled]="disableReportsButton" (click)="printConsolidated()">Consolidated</button>&nbsp;
        <button mat-raised-button color="primary" style="height:30px;vertical-align: top;"
        type="submit" [disabled]="disableReportsButton" (click)="printAllInvoices()">All Invoices</button>&nbsp;
        <button mat-raised-button color="primary" style="height:30px;vertical-align: top;"
        type="submit" [disabled]="disableReportsButton" (click)="getPackingReport()">Packing Report</button>&nbsp;
        <button mat-raised-button color="primary" style="height:30px;vertical-align: top;"
        type="submit" [disabled]="disableReportsButton" (click)="getCuttingReport()">Cutting Report</button>&nbsp;
        <button mat-raised-button color="primary" style="height:30px;vertical-align: top;"
        type="submit" [disabled]="disableEmailButton" (click)="onEmail()">Email</button>&nbsp;
    
        
        <div *ngIf="reportUrl?.length > 0">
          <a href="{{reportUrl}}">Consolidated</a>
        </div>
        <div *ngIf="allInvoicesURL?.length > 0">
          <a href="{{allInvoicesURL}}">Invoices</a>
        </div>
        <div *ngIf="packingReport?.length > 0">
          <a href="{{packingReport}}">Packing Report</a>
        </div>
        <div *ngIf="cuttingReport?.length > 0">
          <a href="{{cuttingReport}}">Cutting Report</a>
        </div>
       </h1> 
    </mat-toolbar-row>
  </mat-toolbar>
</div>



<div *ngIf="isLoading==true" style="text-align: center;margin-top:10px;">
  <img src="/assets/loading.gif" width="50px" height="50px" />
</div>
<mat-tab-group style="width: 100%;"  >
<mat-tab label = "Todays Orders">

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="setSendButtonStatusMasterCheckbox()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [aria-label]="checkboxLabel()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
                   (change)="setSendButtonStatus($event, row)"
                    [checked]="selection.isSelected(row)"
                    [aria-label]="checkboxLabel(row)">
      </mat-checkbox>
    </td>
  </ng-container> 
  <ng-container matColumnDef="Order ID">
    <th class="w-order-no" mat-header-cell *matHeaderCellDef>Order#</th>
    <td mat-cell *matCellDef="let element" 
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    {{element.customerOrderId}} 
    <img [src]="element.changedDuringCustomerOrder==1?messageMark:''" widh="25px" height="25px">
    
  </td>

  </ng-container>
  <ng-container matColumnDef="Track">
    <th class="w-customer" mat-header-cell *matHeaderCellDef>Track</th>
    <td mat-cell *matCellDef="let element"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    <a href="https://www.iperkz.com/tracking.jsp?id={{element.tableNumber}}">link</a></td>
  </ng-container>

  <ng-container matColumnDef="Customer Name">
    <th class="w-customer" mat-header-cell *matHeaderCellDef>Customer</th>
    <td mat-cell *matCellDef="let element"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    {{element.firstName}}, {{element.lastName}}</td>
  </ng-container>

  <ng-container matColumnDef="Order Items">
    <th class="w-order-item" mat-header-cell *matHeaderCellDef>Order Items</th>
    <td mat-cell *matCellDef="let element"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    <!--
    <li *ngFor="let menuItem of element.menuList">
      <b>(#{{menuItem.count}})</b> - {{menuItem.menuCode}} -{{menuItem.menuItemName}} <span *ngIf="menuItem.additionsNames?.length >0">with '{{menuItem.additionsNames}}'</span><b> Instructions:</b> 
      <span *ngIf="menuItem.specialInstructions?.length > 0">{{menuItem.specialInstructions}}</span> <span *ngIf="menuItem.specialInstructions?.length == 0">None</span>- {{menuItem.salePrice | currency:'USD':'symbol':'1.2-2'}}
    </li>
    -->
    </td>
  </ng-container>

  <ng-container matColumnDef="Status">
    <th class="w-status" mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let element"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    <span *ngIf="element.orderStatus == 'PLACED'">New</span>
    <span *ngIf="element.orderStatus == 'STARTED'">In Progress</span>
    <span *ngIf="element.orderStatus == 'COMPLETED'">Completed</span>
    <span *ngIf="element.orderStatus == 'DELETED'">Cancelled</span>
  </td>
  </ng-container>
  <ng-container matColumnDef="Total Amt">
    <th class="w-order-amt" mat-header-cell *matHeaderCellDef>Total Amt</th>
    <td mat-cell *matCellDef="let element"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    Sale Amt&nbsp;&nbsp;&nbsp;: {{element.totalSalePrice | currency:'USD':'symbol':'1.2-2'}}
  <br>
    Taxes&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {{element.tax | currency:'USD':'symbol':'1.2-2'}}
  <br>
    <span [ngClass]="{'diabledText': element.chargeMode =='STORE'}">Card Fee&nbsp;&nbsp;&nbsp;: {{element.transactionFee | currency:'USD':'symbol':'1.2-2'}} </span>
    <br>
  Discount : {{ element.discount | currency:'USD':'symbol':'1.2-2'}}
  <br>
  <span class="focusedText"> Customer Pays: {{ (element.amountChargedToCustomer -  element.discount ) | currency:'USD':'symbol':'1.2-2'}} </span>
   </td>
  </ng-container>
  <ng-container matColumnDef="Payment Mode">
    <th mat-header-cell *matHeaderCellDef>Cash/Card</th>
    <td mat-cell *matCellDef="let element" style="text-align: center;"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    <span *ngIf="element.paymentMode == 'CASH'" class="focusedText"> {{element.paymentMode}}</span>
    <span *ngIf="element.paymentMode != 'CASH'"> {{element.paymentMode}}</span>
   </td>
  </ng-container>
  <ng-container matColumnDef="Order Type">
    <th mat-header-cell *matHeaderCellDef>Order Type</th>
    <td mat-cell *matCellDef="let element" style="text-align: center;"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
     <span *ngIf="element.takeOut == 0" class="focusedText">Dine In #  {{element.tableNumber}}</span>
    <span *ngIf="element.takeOut == 1">Take Out</span>
   </td>
  </ng-container>
  <ng-container matColumnDef="Time">
    <th mat-header-cell *matHeaderCellDef>Time</th>
    <td mat-cell *matCellDef="let element"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    {{element.orderCreationTime | date}}</td>
  </ng-container>
  <ng-container matColumnDef="Phone">
    <th mat-header-cell *matHeaderCellDef>Phone</th>
    <td mat-cell *matCellDef="let element"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    {{element.phone}}</td>
  </ng-container>
  <ng-container matColumnDef="Address">
    <th mat-header-cell *matHeaderCellDef>Address</th>
    <td mat-cell *matCellDef="let element"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    {{element.address}}</td>
  </ng-container>
  <ng-container matColumnDef="Email">
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let element"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    {{element.email}}</td>
  </ng-container>
  
  <ng-container matColumnDef="Started">
      <th mat-header-cell *matHeaderCellDef>Started</th>
      <td mat-cell *matCellDef="let element"
      [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
      <button *ngIf="element.orderProcessStartTime?.length == 0 || element.orderProcessStartTime?.length == null" mat-raised-button color="primary" style="height:30px;vertical-align: top;"
      type="submit" (click)="setStartedStatus(element.customerOrderId)">Started</button>
      <h2 *ngIf="element.orderProcessStartTime?.length > 0" style="height:30px;vertical-align: middle;padding-top: 5px;"
      type="submit" >{{element.packingAssociate}}
      </h2>
      <button  mat-raised-button color="primary" style="height:30px;vertical-align: top;"
      type="submit" (click)="addToPrintQueue(element.customerOrderId)">Print</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="PickedUp">
      <th mat-header-cell *matHeaderCellDef>PickedUp</th>
      <td mat-cell *matCellDef="let element"
      [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
      <button *ngIf="element.orderReadyTime?.length == 0 || element.orderReadyTime?.length == null" mat-raised-button color="primary" style="height:30px;vertical-align: top;"
      type="submit" (click)="setPickupStatus(element.customerOrderId)">Picked Up</button>
        <button *ngIf="element.orderReadyTime?.length > 0" mat-raised-button color="Disabled" style="height:30px;vertical-align: top;"
      type="submit" (click)="setPickupStatus(element.customerOrderId)">Done</button>
      </td>
    </ng-container> 

    <ng-container matColumnDef="exp delievery">
      <th mat-header-cell *matHeaderCellDef>Exp. Delivery Time & Sequence</th>
      <td mat-cell *matCellDef="let element"
      [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
       <div (mouseenter) ="onMouseEnter(element)" (mouseleave)="onMouseLeave(element)">
          
          <section *ngIf="element.editScreen == false">
            {{element.expectedDeliveryTime | date:'h:mm a':'UTC'}} -  <button  mat-mini-fab color="primary">{{element.deliverySeq}}</button>
             
          <img *ngIf="element.expectedDeliveryTimeEditable==true" src="../../assets/edit-icon-1.png" widh="25px" height="25px" 
        (click)="showEditItems(element)">

      </section>
      <section>
        <form *ngIf="element.editScreen == true" [formGroup]="expecteDeliveryTimeForm" > 
          <mat-form-field appearance="fill">
           <input matInput [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" placeholder="{{element.expectedDeliveryTime}}" formControlName="deliveryTime" required
            width="50px" >
           </mat-form-field>
           <owl-date-time [pickerType]="'timer'" #dt1></owl-date-time>&nbsp;
           <mat-form-field appearance="fill">
            <mat-label>Seq</mat-label>
            <mat-select [value]="element.deliverySeq" required (selectionChange)="onSeqChange($event.value, element)" >
              <mat-option  *ngFor="let seq of seqList" [value]="seq" >{{seq}}</mat-option>
            </mat-select>
          </mat-form-field>
           <button mat-raised-button color="primary" (click)="onExpDeliverySave(element)" >ok</button>&nbsp;
          </form>
      </section>
      </div>
      </td>
    </ng-container> 

  <ng-container matColumnDef="Mark Processed">
    <th mat-header-cell *matHeaderCellDef>Confirm?</th>
    <td mat-cell *matCellDef="let element" style="text-align: center;"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
      <div *ngIf="element.orderStatus == 'PLACED'">
      <button mat-mini-fab  withmatTooltip="Sequence">
        <mat-icon matPrefix >thumb_down</mat-icon>
       
      </button>
     </div>
     <div *ngIf="element.orderStatus=='STARTED'">
      <button mat-mini-fab color="primary" matTooltip="Stop?">
        <mat-icon matPrefix >thumb_up</mat-icon>
      </button>
     </div>
     <div *ngIf="element.orderStatus=='COMPLETED'">
      <button mat-mini-fab disabled matTooltip="Stop?">
        <mat-icon matPrefix >thumb_up</mat-icon>
      </button>
     </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="Delete">
    <th mat-header-cell *matHeaderCellDef>Cancel</th>
    <td mat-cell *matCellDef="let element" style="text-align: center;"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">

  </ng-container>
  
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row;columns: displayedColumns"></tr>
</table>
</mat-tab>
<mat-tab label = "Cutting Items ( Line count: {{getCuttingCount()}})">
<div >
<button mat-raised-button color="primary" style="height:30px;vertical-align: top; "
type="button"  (click)="refreshCuttingInfo()">Refresh</button>

<button mat-raised-button color="primary" style="height:30px;vertical-align: top; float:right;"
type="button"  (click)="getAllTodaysCuttings()">Get All Cuttings</button>&nbsp;
</div>
<p></p>
<h1 style="text-align: left;padding-left: 1%;">Summary Table</h1>
<div style="width: 100%; margin-left: 20px;" >
<mat-select (selectionChange)="associateChange($event)" style="width: 200px;background-color: lightblue; ">
  <mat-option  *ngFor="let associate of deliveryAssociatesArr" 
  value="{{associate}}" >{{associate}}</mat-option>
</mat-select>

</div>
<p></p>
<table mat-table [dataSource]="cuttingSummaryForDisplay" class="mat-elevation-z8">
<ng-container matColumnDef="summary-cuttype">
  <th class="w-order-no" mat-header-cell *matHeaderCellDef>Cut Type</th>
  <td mat-cell *matCellDef="let element" >
  {{element.cutType}}</td>
</ng-container>
<ng-container matColumnDef="summary-total">
  <th class="w-order-no" mat-header-cell *matHeaderCellDef>Total</th>
  <td mat-cell *matCellDef="let element" >
  {{element.count}}</td>
</ng-container>
<ng-container matColumnDef="summary-completed">
  <th class="w-order-no" mat-header-cell *matHeaderCellDef>Completed</th>
  <td mat-cell *matCellDef="let element">
  {{element.completedCount}} - Completed</td>
</ng-container>
<ng-container matColumnDef="Name">
  <th class="w-order-no" mat-header-cell *matHeaderCellDef>Item Name</th>
  <td mat-cell *matCellDef="let element">
    <img [src]="element.count==element.completedCount?checkMark:''" widh="25px" height="25px">{{element.name}}</td>
</ng-container>
<tr mat-header-row *matHeaderRowDef="cuttingSummaryHeaders; sticky: true"></tr>
  <tr mat-row *matRowDef="let row;columns: cuttingSummaryHeaders"></tr>
</table>
<br>
<p></p>
<h1 style="text-align: left;padding-left: 1%;">Details</h1>
<table mat-table [dataSource]="cuttindData" class="mat-elevation-z8">
 
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? masterCuttingToggle() : null"
                    [checked]=""
                    [indeterminate]=""
                    [aria-label]="cuttingLabelCheckbox()">
      </mat-checkbox>

    </th>
    <td mat-cell *matCellDef="let row; let i=index;" [ngClass]="{'startedBackground': row.selected ==true}"
    [ngClass]="{'startedBackground': row.cutStatus == 1}">
      {{i+1}} &nbsp;
      <mat-checkbox (click)="$event.stopPropagation()"
                   (change)="onCuttingCheckboxChanged($event, row)"
                   [checked]="row.cutStatus == 1"
                    [aria-label]="cuttingLabelCheckbox(row)"
                    
                    >
      </mat-checkbox>
    </td>

  </ng-container> 
  <ng-container matColumnDef="OrderId">
    <th class="w-order-no" mat-header-cell *matHeaderCellDef>Order#</th>
    <td mat-cell *matCellDef="let element" 
    [ngClass]="{'startedBackground': element.cutStatus == 1}">
    {{element.customerOrderId}}</td>
  </ng-container>
  <ng-container matColumnDef="Quantity">
    <th class="w-order-no" mat-header-cell *matHeaderCellDef>Count</th>
    <td mat-cell *matCellDef="let element" 
    [ngClass]="{'startedBackground': element.cutStatus == 1}">
    {{element.count}}</td>
  </ng-container>
  <ng-container matColumnDef="Name">
    <th class="w-order-no" mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element" 
    [ngClass]="{'startedBackground': element.cutStatus == 1}">
    {{element.cutName}}</td>
  </ng-container>
  <ng-container matColumnDef="Type">
    <th class="w-order-no" mat-header-cell *matHeaderCellDef>Cut Type</th>
    <td mat-cell *matCellDef="let element" 
    [ngClass]="{'startedBackground': element.cutStatus == 1}">
    {{element.cutVisibleText}}</td>
  </ng-container>
  <ng-container matColumnDef="driver">
    <th class="w-order-no" mat-header-cell *matHeaderCellDef>Delivery Associate</th>
    <td mat-cell *matCellDef="let element" 
    [ngClass]="{'startedBackground': element.cutStatus == 1}">
    {{element.deliveryAssociate}}</td>
  </ng-container>
  <ng-container matColumnDef="Amt">
    <th class="w-order-no" mat-header-cell *matHeaderCellDef>Amount</th>
    <td mat-cell *matCellDef="let element" 
    [ngClass]="{'startedBackground': element.cutStatus == 1}">
    {{element.cutPrice | currency:'USD':'symbol':'1.2-2'}}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="cuttingDisplayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row;columns: cuttingDisplayedColumns"></tr>
</table>
</mat-tab>
<mat-tab label = "Juice Items - ( {{getJuicesCount()}} )">
<!--
<div >
<button mat-raised-button color="primary" style="height:30px;vertical-align: top; "
type="button"  (click)="refreshCuttingInfo()">Refresh</button>

<button mat-raised-button color="primary" style="height:30px;vertical-align: top; float:right;"
type="button"  (click)="getAllTodaysCuttings()">Get All Cuttings</button>&nbsp;
</div>
-->
<p></p>
<h1 style="text-align: left;padding-left: 1%;">Summary Table</h1>
<div style="width: 100%; margin-left: 20px;" >
<mat-select (selectionChange)="associateChange($event)" style="width: 200px;background-color: lightblue; ">
  <mat-option  *ngFor="let associate of deliveryAssociatesArr" 
  value="{{associate}}" >{{associate}}</mat-option>
</mat-select>

</div>
<p></p>
<table mat-table [dataSource]="juiceSummaryForDisplay" class="mat-elevation-z8">
<ng-container matColumnDef="summary-cuttype">
  <th class="w-order-no" mat-header-cell *matHeaderCellDef>Cut Type</th>
  <td mat-cell *matCellDef="let element" >
  {{element.cutType}}</td>
</ng-container>
<ng-container matColumnDef="summary-total">
  <th class="w-order-no" mat-header-cell *matHeaderCellDef>Total</th>
  <td mat-cell *matCellDef="let element" >
  {{element.count}}</td>
</ng-container>
<ng-container matColumnDef="summary-completed">
  <th class="w-order-no" mat-header-cell *matHeaderCellDef>Completed</th>
  <td mat-cell *matCellDef="let element">
  {{element.completedCount}} - Completed</td>
</ng-container>
<ng-container matColumnDef="Name">
  <th class="w-order-no" mat-header-cell *matHeaderCellDef>Item Name</th>
  <td mat-cell *matCellDef="let element">
    <img [src]="element.count==element.completedCount?checkMark:''" widh="25px" height="25px">{{element.name}}</td>
</ng-container>
<tr mat-header-row *matHeaderRowDef="cuttingSummaryHeaders; sticky: true"></tr>
  <tr mat-row *matRowDef="let row;columns: cuttingSummaryHeaders"></tr>
</table>
<br>
<p></p>
<h1 style="text-align: left;padding-left: 1%;">Details</h1>
<table mat-table [dataSource]="juiceData" class="mat-elevation-z8">
 
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? masterCuttingToggle() : null"
                    [checked]=""
                    [indeterminate]=""
                    [aria-label]="cuttingLabelCheckbox()">
      </mat-checkbox>

    </th>
    <td mat-cell *matCellDef="let row; let i=index;" [ngClass]="{'startedBackground': row.selected ==true}"
    [ngClass]="{'startedBackground': row.status == 1}">
      {{i+1}} &nbsp;
      <mat-checkbox (click)="$event.stopPropagation()"
                   (change)="onCuttingCheckboxChanged($event, row)"
                   [checked]="row.status == 1"
                    [aria-label]="cuttingLabelCheckbox(row)"
                    
                    >
      </mat-checkbox>
    </td>

  </ng-container> 
  <ng-container matColumnDef="OrderId">
    <th class="w-order-no" mat-header-cell *matHeaderCellDef>Order#</th>
    <td mat-cell *matCellDef="let element" 
    [ngClass]="{'startedBackground': element.status == 1}">
    {{element.customerOrderId}}</td>
  </ng-container>
  <ng-container matColumnDef="Quantity">
    <th class="w-order-no" mat-header-cell *matHeaderCellDef>Count</th>
    <td mat-cell *matCellDef="let element" 
    [ngClass]="{'startedBackground': element.status == 1}">
    {{element.count}}</td>
  </ng-container>
  <ng-container matColumnDef="Name">
    <th class="w-order-no" mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element" 
    [ngClass]="{'startedBackground': element.status == 1}">
    {{element.cutName}}</td>
  </ng-container>
  <ng-container matColumnDef="Type">
    <th class="w-order-no" mat-header-cell *matHeaderCellDef>Cut Type</th>
    <td mat-cell *matCellDef="let element" 
    [ngClass]="{'startedBackground': element.status == 1}">
    {{element.cutVisibleText}}</td>
  </ng-container>
  <ng-container matColumnDef="driver">
    <th class="w-order-no" mat-header-cell *matHeaderCellDef>Delivery Associate</th>
    <td mat-cell *matCellDef="let element" 
    [ngClass]="{'startedBackground': element.status == 1}">
    {{element.deliveryAssociate}}</td>
  </ng-container>
  <ng-container matColumnDef="Amt">
    <th class="w-order-no" mat-header-cell *matHeaderCellDef>Amount</th>
    <td mat-cell *matCellDef="let element" 
    [ngClass]="{'startedBackground': element.status == 1}">
    {{element.cutPrice | currency:'USD':'symbol':'1.2-2'}}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="cuttingDisplayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row;columns: cuttingDisplayedColumns"></tr>
</table>
</mat-tab>
</mat-tab-group>