
export interface SimpleResponse{

    result:boolean;
	messageCode:number;
	message:string;
}

export class RequestSource {

	customerOrderId:number;
	deliverySequence:number;
	expectedDeliveryTime:string;
}

export class UpdateResponse {
	responseCode:number;
	message: string;
}