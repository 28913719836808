export class MenuCategory{

    storeId: number;
    categoryName:string;
    mealTypeId:Date;
    salePrice:number;
    menuItems:Array<MenuItem>;
}

export class EmailMessage {
    subject:string;
    message:string;
    firstname:string;
    customerIds:string;
    emailIds:string[];
}

export class MenuItem {
    menuId:number;
    storeId:number;
    menuCode:String;
    menuItemName: string;
    menuItemDescription: string;
    imageUrl: string;
    orgPrice: number;
    categoryId:number;
    salePrice: number;
    startTime:string;
    endTime:string;
    subCategory: string;
    mealCategory: string;
    status: string;
    suffix: string;
    subCategoryDisplaySeq: number;
    additions: Array<MenuItemAdditions>;
}


export class MenuItemAdditions {
    menuId:number;
    storeId:number;
	additionsId:number;
	name:string;
	description:string;
    price:number;
    parentId: number;
    childCount: number;
    selectionType: string;
}
export class PrintJob {
    orderId:string;
}