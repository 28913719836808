import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { RequestStatus,ReportUrlWrapper, PerkzAddRequest, Wrapper, PerkzWrapper, CustomerViewWrapper, FileWrapper, TestCustomerWrapper } from './constants';
import { environment } from '../environments/environment';
import { MenuCategory, MenuItem, MenuItemAdditions, PrintJob } from './_models/menu-changes';
import { RequestSource, SimpleResponse, UpdateResponse } from './SimpleResponse';
import { OrderReportCriteria, MenuCriteria, PrebookReportCriteria } from './OrderReportCriteria';
import { GenerateDeliveryRoute } from './super-admin/DeliveryRoute';
import { StoreSummary, UpdateCustomerAttributesRequest } from './customers';
import { CustomerOrderChanges, CustomerOrderMenu, FoodSubItem, FoodSubItemUpdateRequest } from './order';
import { sequence } from '@angular/animations';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private downloadReportURL = environment.baseUrl + 'download';
  private searchByAddressURL:string = environment.baseUrl + "searchOrders";
  private storesUrl = environment.baseUrl + 'ios/store'; 
  private categoriesUrl = environment.baseUrl + 'menu/ios/category/'; 
  private updateCategoriesOrderURL = environment.baseUrl + 'admin/ios/category';
  private updateMenuItemOrderURL = environment.baseUrl + 'admin/ios/menu';
  private ordersByCriteria = environment.baseUrl + 'ordersByCriteria';
  private todaysDeliveryOrders = environment.baseUrl + 'orders/todaydeliverydate/get';

  private ordersByTrackingId = environment.baseUrl + 'ordersByTrackingId';
  private adminMenuList = environment.baseUrl + 'menu/admin/'; 
  private getAllCustomerOrdersURL =  environment.baseUrl + 'ios/order/';
  private customerAttributes = environment.baseUrl + "customer/attributes/";
  private customerAttributesByOrderId = environment.baseUrl + "customer/attributesByOrderId/";
  private customerAttributesByPhone = environment.baseUrl + "customer/attributesByPhone/";
  private modifyCustomerAddressURL = environment.baseUrl + "customer/attributes/modify";
  
  private customerPerkzAddURL = environment.baseUrl + 'addPerkzToCust';

  private adminMenuListByCriteria = environment.baseUrl + 'menu/admin/criteria';
  private updateMenuItemByCategoryOrderURL = environment.baseUrl + 'admin/menu/bycategory/reorder';
  private deleteAdditionURL = environment.baseUrl + 'admin/deleteAddition';
  private changeAdditionPriceURL = environment.baseUrl + 'admin/changeAdditionPrice';
  private statusDelivered = environment.baseUrl + 'admin/updateStatusDelivered';

  private todaysOrdersURL = environment.baseUrl + 'todaysorders';

  private customerPerkzURL = environment.baseUrl + 'perkzbycustomer/';

  private setStatusPackingStartedURL = environment.baseUrl + "admin/updateStatusPackingStarted";
  private setStatusDeliveryStartedURL = environment.baseUrl + "admin/updateStatusDeliveryStarted";
  private getConsolidatedURL = environment.baseUrl + "reports/groceries/consolidated";
  private allTodaysInvoicesURL = environment.baseUrl + "reports/allinvoices";

  private packingReportURL = environment.baseUrl + "reports/packingReport";
  private cuttingReportURL = environment.baseUrl + "reports/cuttingReport";
  private cuttingReportData = environment.baseUrl + "reports/cuttingData";
  private cuttingItemUpdate =  environment.baseUrl + "reports/cuttingData/update";

  private prebookOrdersByCriteria =  environment.baseUrl + "prebook/getBookingOrdersByCriteria";
  private getPrebookReportItemsURL =  environment.baseUrl + "prebook/getReportItems";
  private getDailyPandLURL =  environment.baseUrl + "reports/getDailyPandL";


  private orderChanges = environment.baseUrl + "orderChanges";
  private approveChanges = environment.baseUrl + "orderChangesApproval";
  
  private createCustomerTest = environment.baseUrl + 'customerCreate/';
  private getTestCustomersURL = environment.baseUrl + 'test/getTestCustomers';

  private createTestOrderURL = environment.baseUrl + "test/ios/v2/order/";
  private sendInviteURL = environment.baseUrl + "test/invites/create/";

  private getcategoriesUrl = environment.baseUrl + 'categories';
  private getMenuByCatURL = environment.baseUrl + 'admin/menudetails/';
  private expectedDeliveryDetailsURL = environment.baseUrl + 'updateOrderExpDeliveryTime';

  private ADD_TO_PRINT_QUEUE = environment.baseUrl + 'createprintjobs';
  
  constructor(private http: HttpClient) { }


  getAllStores (): Observable<Wrapper> {
    return this.http.get<Wrapper>(this.storesUrl);
  }


  getAllMenuCategories(storeId:string, mealType:string): Observable<Wrapper> {
    var url = this.categoriesUrl + storeId + "/" + mealType;
    return this.http.get<Wrapper>(url);
  }

  changeCategoryOrder(menuCategories:MenuCategory[], storeId:Number, mealTypeId:Number): Observable<SimpleResponse> {
    var url:string = this.updateCategoriesOrderURL + "/" + storeId + "/" + mealTypeId;

    return this.http.post<SimpleResponse>(url, menuCategories);
  }

  changeMenuOrder(menuCategories:MenuItem[],  storeId:number, categoryID:number, subCategoryID:number): Observable<SimpleResponse> {
    var url:string = this.updateMenuItemOrderURL + "/" + storeId +  "/" + subCategoryID+  "/" + categoryID;
    return this.http.post<SimpleResponse>(url, menuCategories);
  }


  changeMenuOrderForCategory(menuItems:MenuItem[],  storeId:Number) : Observable<SimpleResponse> {
    var url:string = this.updateMenuItemByCategoryOrderURL +"/" + storeId;
    return this.http.post<SimpleResponse>(url, menuItems);
  }
  

  getAllMenuItemsByCategory(storeId:number): Observable<CustomerViewWrapper> {
    var url = this.adminMenuList + storeId  ;
    return this.http.get<CustomerViewWrapper>(url);
  }

  getCategories(): Observable<Wrapper> {
    return this.http.get<Wrapper>(this.getcategoriesUrl);
  }

  getMenuByCategory(storeId:number, categoryId:number, subCategoryID:number): Observable<Wrapper> {
    var url = this.getMenuByCatURL+storeId;
    return this.http.post<Wrapper>(url, {menuCategoryId:categoryId, subCategoryId:subCategoryID});
  }
  

  getAllMenuItems(storeId:number, mealType:number): Observable<Wrapper> {
    var url = this.adminMenuList + storeId + "/" + mealType ;
    return this.http.get<Wrapper>(url);
    /*
    if (mealType == -1) {
      var url = this.adminMenuList + storeId ;
      return this.http.get<Wrapper>(url);
    } else {
      var url = this.adminMenuList + storeId + "/" + mealType ;
      return this.http.get<Wrapper>(url);
    }
    */
  }
  
  getPrebookReportItems(criteria:PrebookReportCriteria):Observable<Wrapper> {
    return this.http.post<Wrapper>(this.getPrebookReportItemsURL, criteria);
  }

  getDailyPandLItems(criteria:PrebookReportCriteria):Observable<Wrapper> {
    return this.http.post<Wrapper>(this.getDailyPandLURL, criteria);
  }

  getPrebookOrdersByCriteria(criteria:OrderReportCriteria):Observable<Wrapper> {
    return this.http.post<Wrapper>(this.prebookOrdersByCriteria, criteria);
  }
  
  getCustomerOrdersByDate(criteria:OrderReportCriteria):Observable<Wrapper> {
    return this.http.post<Wrapper>(this.ordersByCriteria, criteria);
  }

  getCustomerOrdersByTodaysDate():Observable<Wrapper> {
    return this.http.get<Wrapper>(this.todaysDeliveryOrders);
  }
  

  getCustomerOrdersByTrackingId(criteria:string, associate:string):Observable<Wrapper> {
    criteria = criteria +"@"+associate;
    return this.http.post<Wrapper>(this.ordersByTrackingId, criteria);
  }

  setOrderStatusDelivered(orderId:number):Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(this.statusDelivered, orderId);
  }

  downloadReport(criteria:OrderReportCriteria):Observable<FileWrapper> {
    
     let myHeaders = new HttpHeaders();
     myHeaders.append('responseType', 'blob');
     myHeaders.append('Accept', 'application/pdf');
     
    
    
   //  return this.http.post<RequestStatus>(url, formData, {headers:myHeaders});
    return this.http.post<FileWrapper>(this.downloadReportURL, criteria, {headers:myHeaders} );

  }

  getMenuItemsByCriteria( criteria:MenuCriteria):Observable<Wrapper> {
    return this.http.post<Wrapper>(this.adminMenuListByCriteria, criteria);
  }
  

  deleteAddition(addition:MenuItemAdditions): Observable<SimpleResponse> {
    var url:string = this.deleteAdditionURL;
    return this.http.post<SimpleResponse>(url, addition);
  }

  changeAdditionPrice(addition:MenuItemAdditions): Observable<SimpleResponse> {
    var url:string = this.changeAdditionPriceURL;
    return this.http.post<SimpleResponse>(url, addition);
  }


  getTodaysOrders():Observable<Wrapper> {
   return this.http.get<Wrapper>(this.todaysOrdersURL);
  }


  setOrderChanges(data:CustomerOrderChanges):Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(this.orderChanges, data);
  }

  approveOrderChanges(data:CustomerOrderChanges):Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(this.approveChanges, data);
  }


  setOrderStarted(data:GenerateDeliveryRoute):Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(this.setStatusPackingStartedURL, data);
   }
   
  /* 
   setOrderStarted(data:GenerateDeliveryRoute): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(this.setStatusPackingStartedURL, data, this.httpOptions);
  }
  */

   setOrderPickedUp(orderId:number):Observable<SimpleResponse> {
    return this.http.get<SimpleResponse>(this.setStatusDeliveryStartedURL + "/" + orderId);
   }

   searchByAddress(address:string):Observable<Wrapper>{
      return this.http.post<Wrapper>(this.searchByAddressURL, address);
   }
   
   getAllCustomerOrders(customerId:number):Observable<Wrapper>{
    var url:string = this.getAllCustomerOrdersURL + customerId;
    return this.http.get<Wrapper>(url);
  }

  getCustomerAttributes (customerId:number):Observable<Wrapper>{
    var url:string = this.customerAttributes + customerId;
    return this.http.post<Wrapper>(url, new StoreSummary());
  }

  getCustomerAttributesByOrderId (orderId:number):Observable<Wrapper>{
    var url:string = this.customerAttributesByOrderId + orderId;
    return this.http.get<Wrapper>(url);
  }

  getCustomerAttributesByPhone (phone:string):Observable<Wrapper>{
    var url:string = this.customerAttributesByPhone + phone;
    return this.http.get<Wrapper>(url);
  }

  getModifyCustomerAddressURL (request:UpdateCustomerAttributesRequest):Observable<RequestStatus> {
    return this.http.post<RequestStatus>(this.modifyCustomerAddressURL, request);
  }
  

  getCustomerPerkz(customerId:number):Observable<PerkzWrapper> {
    return this.http.get<PerkzWrapper>(this.customerPerkzURL+customerId);
   }

   addCustomerPerkz(request:PerkzAddRequest):Observable<SimpleResponse> {
     return this.http.post<SimpleResponse>(this.customerPerkzAddURL, request);
   }

   getConsolidatedReport(list:number[]):Observable<ReportUrlWrapper> {
    return this.http.post<ReportUrlWrapper>(this.getConsolidatedURL, list);
   }

   getAllTodaysInvoices(list:number[]):Observable<ReportUrlWrapper> {
    return this.http.post<ReportUrlWrapper>(this.allTodaysInvoicesURL, list);
    
   }

   getPackingReport(list:number[]):Observable<ReportUrlWrapper> {
    return this.http.post<ReportUrlWrapper>(this.packingReportURL, list);
   // return this.http.post<SimpleResponse>(tempUrl, arr);
   }

   getCuttingReport(list:number[]):Observable<ReportUrlWrapper> {
    return this.http.post<ReportUrlWrapper>(this.cuttingReportURL, list);
   }

   getCuttingData(list:number[]):Observable<Wrapper>  {
    return this.http.post<Wrapper>(this.cuttingReportData, list);
   }

   updateCuttingRecord(request:FoodSubItemUpdateRequest):Observable<SimpleResponse>  {
    return this.http.post<SimpleResponse>(this.cuttingItemUpdate, request);
   }

   createCustomer(firstname:string, phone:number, email:number):Observable<SimpleResponse> {
     return this.http.get<SimpleResponse>(this.createCustomerTest+firstname 
            + "/" +phone +"/" + email);
   }

   getTestCustomers():Observable<TestCustomerWrapper>{
    return this.http.get<TestCustomerWrapper>(this.getTestCustomersURL);
   }

   createTestOrder(customerId:string):Observable<SimpleResponse> {
    return this.http.get<SimpleResponse>(this.createTestOrderURL + customerId);
   }

   sendInvite(customerId:string, phone:string, email:string):Observable<SimpleResponse> {
    return this.http.get<SimpleResponse>(this.sendInviteURL 
      + customerId + "/" + phone + "/" + email);
   }

   saveExpectedDeliveryDetails(seq:number, dateStr:string, customerOrderId:number): Observable<UpdateResponse> {
    var url = this.expectedDeliveryDetailsURL;
   
    let test = new HttpHeaders();
    test.append('contentType', 'application/json');
    test.append('Accept', '*/*');
    test.append('Access-Control-Allow-Origin', '*');
    test.append('Access-Control-Allow-Headers', 'Content-Type, Authorization');
    
    var testObj:RequestSource =  new RequestSource();
    testObj.customerOrderId = customerOrderId;
    testObj.deliverySequence = seq;
    testObj.expectedDeliveryTime = dateStr;
    return this.http.post<UpdateResponse>(url, testObj);
  }

  addToPrintQueue(orderId: number) :Observable<SimpleResponse>{
    var url = this.ADD_TO_PRINT_QUEUE;
    var job:PrintJob = new PrintJob();
    job.orderId = orderId+"";
    return this.http.post<SimpleResponse>(url, job);
  }
   

  /*
  , {headers:{'Access-Control-Allow-Origin':'*',
      'Access-Control-Allow-Headers' :'Content-Type, Authorization',
      'Access-Control-Allow-Methods': 'POST',
    }} 
      */
}